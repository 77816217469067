:root {
    // Hotfix for Tailwind 3's transform utilities being buggy in Safari <=14
    // https://github.com/tailwindlabs/tailwindcss/discussions/8788
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;

    // The width of the viewport, minus outer margins – or the value of the max-content-width variable, whicheever is smaller
    --content-width: min((100vw - (var(--outer-margin)*2)), var(--max-content-width));
}

.lazyload,
.lazyloading {
    opacity: 0;
}

.lazyloaded {
    transition: opacity 0.6s theme('transitionTimingFunction.out-quad');
}

.lazyload.lazyzoom, .lazyloading.lazyzoom {
    transform: scale(1.05, 1.05) translate(0, 2vh);
    transform-origin: center bottom;
}

.lazyloaded.lazyzoom {
    transform: scale(1, 1) translate(0, 0);
    transform-origin: center bottom;
    transition: opacity 0.5s theme('transitionTimingFunction.in-sine'), transform 1s theme('transitionTimingFunction.out-quad');
}

.no-js img.lazyload[data-src],
.no-js img.lazyload[data-srcset] {
    display: none;
}

html.outline,
html.no-outline * {
    outline: none !important;
}

// Prevent grid blowout on tiny screens
*:not([data-gridoverlay]) > .grid:not(.blowout) {
    // Max-width = (outerMargins * 2) + (gutter width * number of columns)
    @media (max-width: calc((theme('gutterMap.default') * theme('gridColumns')) + (theme('outerMarginsMap.default') * 2))) {
        grid-template-columns: minmax(0, 1fr);
    }
}

svg {
    display: block;
}

video[playsinline] {
    pointer-events: none;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

input[disabled] { // iOS fix
    opacity: 1;
}

button {
    appearance: none;
    font-weight: inherit;
    text-align: left;
}

[hidden] {
    display: none !important;
}

input:required {
    box-shadow: none !important;
}

select {
    appearance: none;
}

input[class*="appearance-none"] {
    -webkit-appearance: none;
}

table {
    th, td {
        text-align: left;
        padding: 0;
        font-weight: normal;
        vertical-align: top;
    }
}

strong, b {
    font-weight: normal;
}

i, em {
    font-style: italic;
}

.hit::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    min-width: 44px;
    min-height: 44px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

img, video, svg {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

a, button {
    &[disabled],
    &[aria-disabled="true"] {
        cursor: default;
    }
    html.using-touch & {
        touch-action: manipulation;
    }
}

details > summary {
    list-style: none;
    cursor: pointer;
}

details > summary > * {
    display: inline;
}

details > summary::-webkit-details-marker {
    display: none;
}

.skiplink:not(:focus) {
    clip: rect(1px, 1px, 1px, 1px);
    display: block;
    height: 1px;
    overflow: hidden;
    padding: 20px;
    position: absolute;
    text-decoration: underline;
    top: 1rem;
    left: 1rem;
    width: 1px;
    z-index: 999;
}

#content {
    scroll-margin-top: 64px;
    margin-bottom: 48px;
    @screen m {
        margin-bottom: 80px;
    }
    @screen l {
        margin-bottom: 144px;
    }
}

.squish:not([aria-disabled="true"]) {
    transition: transform 0.1s linear;
    transform: scale(1);
    &:active,
    .group:active & {
        transform: scale(calc(1 - var(--squishyness, 0.03)));
    }
    @media screen and (prefers-reduced-motion: reduce), (update: slow) {
        transform: none !important;
    }
}

@keyframes animated-gradient {
    0% {
        background-position: 25% 100%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 25% 100%;
    }
}
.animated-gradient {
    background: linear-gradient(to right, var(--color1) 2.68%, var(--color2)) 96.73%;
    @media screen and (prefers-reduced-motion: no-preference) and (update: fast) {
        background-size: 175% 100%;
        animation: animated-gradient 5s ease infinite;
    }
}

@media screen and (prefers-reduced-motion: no-preference) and (update: fast) {
    .hover-scale {
        @apply scale-[1.01] group-hover:scale-[1.04];
        transition: transform 0.5s;
        transform-origin: center 75% !important;
    }
}

.animated-gradient-text {
    @supports (background-clip: text) {
        background: linear-gradient(
                90deg,
                var(--theme-fg) 0 30%,
                var(--theme-accent, var(--color-kobberglod)) 60%,
                var(--theme-accent, var(--color-kobberglod)) 70%,
                var(--theme-fg)
        )
        300% 0 / 300% 100%;
        color: transparent;
        background-clip: text;
        line-height: 1.3;
        &.font-norman {
            line-height: 1.35;
        }
    }
}

@media screen and (prefers-reduced-motion: no-preference) and (update: fast) {
    .animated-gradient-text {
        animation: 5s linear 0s 2 move-bg;
    }
    @keyframes move-bg {
        80% {
            background-position: 150% 0;
        }
        100% {
            background-position: 150% 0;
        }
    }
}

// Reveals
html.js:not(.js-error):not(.is-preview.is-iframe) [data-reveal]:not([data-revealed]) {
    opacity: 0.001;
    @media screen and (prefers-reduced-motion: reduce), (update: slow) {
        transform: none !important;
        opacity: 1 !important;
    }
}
