.wysiwyg {
    & > * {
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    p {
        margin: calc((22/16) * 1em) 0;
    }

    em, i {
        font-style: italic;
    }

    b, strong {
        font-weight: 600;
    }

    h2 {
        @apply text-h3 font-apk font-normal;// mb-16 m:mb-24;
        margin-top: max(1.25em, 40px);
        margin-bottom: max(0.3em, 16px);
        @screen m {
            margin-top: max(1.25em, 56px);
            margin-bottom: max(0.3em, 24px);
        }
        @screen l {
            margin-top: max(1.25em, 72px);
        }
    }

    h3 {
        @apply text-h5 font-medium;
        margin-top: 2em;
        margin-bottom: 0.3em;
    }

    h2, h3 {
        & + p {
            margin-top: 0;
        }
        & + ul,
        & + ol,
        & + .inline-accordion {
            @apply mt-16 m:mt-24;
        }
    }
    
    a:not([class]) {
        @apply link;
    }

    ul, ol {
        margin: 3.04em 0;

        li {
            padding-left: 2.09em;
            margin-bottom: 1.5em;
            position: relative;

            &::before {
                content: "";
                display: block;
                width: 16px;
                height: 16px;
                position: absolute;
                top: 0;
                left: 0;

                @screen l {
                    width: 24px;
                    height: 24px;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    ul li::before {
        top: 0.15em;
        background-color: currentColor;
    }

    ol {
        counter-reset: list_counter;
    }

    ol li::before {
        text-align: center;
        counter-increment: list_counter;
        content: counter(list_counter)'.';
    }

    ul > li > ul,
    ol > li > ol {
        margin: 1.5em 0;
    }

}
