.mapboxgl {
    
    &-popup {
        
    }
    
    &-popup-content {
        border-radius: 0 !important;
        padding: 0 !important;
    }
    
    &-popup-close-button {
        z-index: 4;
        background: rgba(255, 255, 255, 0.6) !important;
        width: 24px;
        height: 24px;
        border-radius: 12px !important;
        backdrop-filter: blur(12px);
        top: 7px !important;
        right: 7px !important;
        color: transparent;
        transition: background 0.2s ease;
        
        &:hover {
            background: rgba(255, 255, 255, 0.85) !important;
        }
        
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 16px;
            height: 16px;
            left: 4px;
            top: 4px;
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_3584_62918' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='16' height='16'%3E%3Cpath d='M0 0H16V16H0V0Z' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_3584_62918)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.3075 13.2156L2.87946 3.78754L3.82227 2.84473L13.2504 12.2728L12.3075 13.2156Z' fill='%23262221'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.2104 3.78393L3.78233 13.212L2.83984 12.2695L12.2679 2.84144L13.2104 3.78393Z' fill='%23262221'/%3E%3C/g%3E%3C/svg%3E%0A");        
        }
    }
    
    &-popup-tip {
        display: none !important;
    }
}
