input[type=range].styled-slider {
        height: 32px;
        background: transparent;
        -webkit-appearance: none;
    }

    /*progress support*/
    input[type=range].styled-slider.slider-progress {
        --range: calc(var(--max) - var(--min));
        --ratio: calc((var(--value) - var(--min)) / var(--range));
        --sx: calc(0.5 * 32px + var(--ratio) * (100% - 32px));
    }

    input[type=range].styled-slider:focus {
        outline: none;
    }

    /*webkit*/
    input[type=range].styled-slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 32px;
        height: 32px;
        border-radius: 2px;
        background: #F3BFA2;
        border: none;
        box-shadow: none;
        margin-top: calc(8px * 0.5 - 32px * 0.5);
        cursor: grab;
    }

    input[type=range].styled-slider::-webkit-slider-runnable-track {
        height: 8px;
        border: none;
        border-radius: 2px;
        background: #fff;
        box-shadow: none;
    }

    input[type=range].styled-slider::-webkit-slider-thumb:hover {
        background: #F3BFA2;
    }

    input[type=range].styled-slider:hover::-webkit-slider-runnable-track {
        background: #fff;
    }

    input[type=range].styled-slider::-webkit-slider-thumb:active {
        background: #F3BFA2;
        cursor: grabbing;
    }

    input[type=range].styled-slider:active::-webkit-slider-runnable-track {
        background: #fff;
    }

    input[type=range].styled-slider.slider-progress::-webkit-slider-runnable-track {
        background: linear-gradient(#EF9D83, #EF9D83) 0/var(--sx) 100% no-repeat, #fff;
    }

    input[type=range].styled-slider.slider-progress:hover::-webkit-slider-runnable-track {
        background: linear-gradient(#EF9D83, #EF9D83) 0/var(--sx) 100% no-repeat, #fff;
    }

    input[type=range].styled-slider.slider-progress:active::-webkit-slider-runnable-track {
        background: linear-gradient(#EF9D83, #EF9D83) 0/var(--sx) 100% no-repeat, #fff;
    }

    /*mozilla*/
    input[type=range].styled-slider::-moz-range-thumb {
        width: 32px;
        height: 32px;
        border-radius: 2px;
        background: #F3BFA2;
        border: none;
        box-shadow: none;
    }

    input[type=range].styled-slider::-moz-range-track {
        height: 8px;
        border: none;
        border-radius: 2px;
        background: #fff;
        box-shadow: none;
    }

    input[type=range].styled-slider::-moz-range-thumb:hover {
        background: #F3BFA2;
    }

    input[type=range].styled-slider:hover::-moz-range-track {
        background: #fff;
    }

    input[type=range].styled-slider::-moz-range-thumb:active {
        background: #F3BFA2;
    }

    input[type=range].styled-slider:active::-moz-range-track {
        background: #fff;
    }

    input[type=range].styled-slider.slider-progress::-moz-range-track {
        background: linear-gradient(#EF9D83, #EF9D83) 0/var(--sx) 100% no-repeat, #fff;
    }

    input[type=range].styled-slider.slider-progress:hover::-moz-range-track {
        background: linear-gradient(#EF9D83, #EF9D83) 0/var(--sx) 100% no-repeat, #fff;
    }

    input[type=range].styled-slider.slider-progress:active::-moz-range-track {
        background: linear-gradient(#EF9D83, #EF9D83) 0/var(--sx) 100% no-repeat, #fff;
    }

    /*ms*/
    input[type=range].styled-slider::-ms-fill-upper {
        background: transparent;
        border-color: transparent;
    }

    input[type=range].styled-slider::-ms-fill-lower {
        background: transparent;
        border-color: transparent;
    }

    input[type=range].styled-slider::-ms-thumb {
        width: 32px;
        height: 32px;
        border-radius: 2px;
        background: #F3BFA2;
        border: none;
        box-shadow: none;
        margin-top: 0;
        box-sizing: border-box;
    }

    input[type=range].styled-slider::-ms-track {
        height: 8px;
        border-radius: 2px;
        background: #fff;
        border: none;
        box-shadow: none;
        box-sizing: border-box;
    }

    input[type=range].styled-slider::-ms-thumb:hover {
        background: #F3BFA2;
    }

    input[type=range].styled-slider:hover::-ms-track {
        background: #fff;
    }

    input[type=range].styled-slider::-ms-thumb:active {
        background: #F3BFA2;
    }

    input[type=range].styled-slider:active::-ms-track {
        background: #fff;
    }

    input[type=range].styled-slider.slider-progress::-ms-fill-lower {
        height: 8px;
        border-radius: 2px 0 0 2px;
        margin: 0 0 0 0;
        background: #EF9D83;
        border: none;
        border-right-width: 0;
    }

    input[type=range].styled-slider.slider-progress:hover::-ms-fill-lower {
        background: #EF9D83;
    }

    input[type=range].styled-slider.slider-progress:active::-ms-fill-lower {
        background: #EF9D83;
    }
